// extracted by mini-css-extract-plugin
export var bigimg = "ImageSection-module--bigimg--DrQac";
export var box = "ImageSection-module--box--r5Eql";
export var box2 = "ImageSection-module--box2--WcN+x";
export var container = "ImageSection-module--container--hh+kn";
export var firstSection = "ImageSection-module--firstSection--CEbog";
export var imagesSection1 = "ImageSection-module--imagesSection1--Gg2YU";
export var join = "ImageSection-module--join--7Ye6d";
export var quotes = "ImageSection-module--quotes--+Mf+R";
export var secondSection = "ImageSection-module--secondSection--DyF1F";
export var section2 = "ImageSection-module--section2--F9hUj";
export var typewriter = "ImageSection-module--typewriter--aHeaV";