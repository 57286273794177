import React from 'react';
import * as styles from './HiringJoinWorld.module.scss';
import { StaticImage } from "gatsby-plugin-image";
import image1 from '../../../images/hiring-i1.png';
import image2 from '../../../images/hiring-i2.png';
import image3 from '../../../images/hiring-i3.png';
import image4 from '../../../images/hiring-i4.png';

const HiringJoinWorld = () => {
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.textContainer}>
                    <h3>Join the WiZR world</h3>
                    <p>Your Favorite Work Space.Our ultimate goal is to make the MASTER WiZR reality a place you love to hang out in, be productive in collaborating, presenting, and doing meetings. We want your workspace to be as inspiring, efficient, and fun as possible.</p>
                    <div className={styles.icons}>
                        <div className={styles.icon}>
                            <img src={image1} alt='Icon'/>
                            <p>Entrepreneur</p>
                        </div>
                        <div className={styles.icon}>
                        <img src={image2} alt='Icon'/>
                            <p>Team Mindset</p>
                        </div>
                        <div className={styles.icon}>
                        <img src={image3} alt='Icon'/>
                            <p>Flexible</p>
                        </div>
                        <div className={styles.icon}>
                        <img src={image4} alt='Icon'/>
                            <p>Excellence</p>
                        </div>
                    </div>
                </div>
                <div>
                    <StaticImage src='../../../images/hiringsectiongreen.webp' alt='Image'/>
                </div>
            </div>
        </div>
    );
};

export default HiringJoinWorld;
