import React from 'react';
import * as styles from './ImageSection.module.scss';
import {StaticImage} from "gatsby-plugin-image";
import HiringQuotes from "../../atoms/HiringQuotes";
import Typewriter from 'typewriter-effect';
import StyledButton from '../../atoms/StyledButton';
const ImageSection = () => {
    return (
        <div className={styles.container}>
            <div className={styles.imagesSection1}>
                <div className={styles.firstSection}>
                <StaticImage src='../../../images/Hiring-7.jpg' alt='ImageSection' />
                 <StaticImage src='../../../images/Hiring-6.jpg' alt='ImageSection' />
                 <div className={styles.box}>
                 <p><div className={styles.typewriter} >
                    <Typewriter
                    options={{
                    strings: ['Fun <br/> & <br/> Wellness','Think <br/> & <br/> Create', 'Connect <br/> & <br/> Share'],
                    autoStart: true,
                    loop: true,
                    delay:150
                     }}
                    />
                    </div>
                    </p>
                    </div>
                    <StaticImage src='../../../images/hiringimage3.webp' alt='ImageSection' />
                    </div>
                    </div>
                    <div className={styles.section2}>
                    <div className={styles.quotes}>
                    <HiringQuotes />
                    </div>
                    <StaticImage src='../../../images/Hiring-5.jpg' alt='ImageSection' />
                    <div className={styles.box2}>
                    <p>Mastering<br/> Remote <br/> Work </p>
                    </div>
                    </div> 
                    <div className={styles.section2}>
                    <StaticImage src='../../../images/hiringimage6.webp' alt='ImageSection' />
                    <div className={styles.box}>
                    <p>
                     <div className={styles.typewriter} >
                    <Typewriter
                    options={{
                    strings: ['A <br/> Place <br/> to <br/> Connect', 'Organize <br/> & <br/> Collaborate'],
                    autoStart: true,
                    loop: true,
                    delay:150
                    }}
                     />
                     </div>
                     </p>
                 </div>
                   <div className={styles.bigimg}>
                   <StaticImage src='../../../images/hiringimage7.webp' alt='ImageSection' />
                   </div>
                    </div> 
                    <div className={styles.section2}>
                    <div className={styles.bigimg}>
                    <StaticImage src='../../../images/hiringimage9.webp' alt='ImageSection' />
                    </div>
                    <StaticImage src='../../../images/Hiring-3.jpg' alt='ImageSection' />
                    <StaticImage src='../../../images/Hiring-10.jpg' alt='ImageSection' />
            </div> 
            <div className={styles.join}>
          <h2>Join our team</h2>
          <StyledButton
            icon
            title="Apply Now"
            extraClass="graybtn"
            link="#"
          />
        </div>
        </div>
    );
};

export default ImageSection;