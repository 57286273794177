import React, { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import FreeTrial from "../components/atoms/freeTrial";
import HeroBanner from "../components/atoms/HeroBanner";
import HiringJoinWorld from "../components/atoms/HiringJoinWorld";
import HiringWays from "../components/atoms/HiringWays";
import ImageSection from "../components/molecules/ImageSection";
import OpenPositions from "../components/ogranisms/OpenPositions";
import StyledButton from "../components/atoms/StyledButton";
import Head from "../components/ogranisms/head";
import { IoIosArrowForward } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
function Index() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <>
      <Head
        title="Live your best professional life"
        description="Join the WiZR world
        Your Favorite Work Space.Our ultimate goal is to make the MASTER WiZR reality a place you love to hang out in, be productive in collaborating, presenting, and doing meetings. We want your workspace to be as inspiring, efficient, and fun as possible."
        ogTitle="Hiring"
      />
      <main>
        <Header />
        <HeroBanner filename="hiring-banner.png" extraClass="hiringPage">
          <h3 data-aos="zoom-in" data-aos-delay="100">
            Join our mission
          </h3>
          <h1 data-aos="zoom-in" data-aos-delay="200">
            Live your best professional life
          </h1>
          <div data-aos="flip-down" data-aos-delay="100">
            <StyledButton
              title="Open Positions"
              icon={<IoIosArrowForward />}
              extraClass="whiteBtn"
              link="#"
            />
          </div>
        </HeroBanner >
        <HiringJoinWorld />
        <OpenPositions />
        <ImageSection />
        <HiringWays />
        <FreeTrial />
        <Footer />
      </main>
    </>
  );
}

export default Index;
