import React from "react";
import * as styles from './freeTrial.module.scss';
import {StaticImage} from "gatsby-plugin-image";
import StyledButton from "../StyledButton";
import { IoIosArrowForward } from "react-icons/io";
import image from '../../../images/getwizrlogo.png';
const FreeTrial = () => {
    return(
      <div className={styles.container}>
          <div data-aos="zoom-out" data-aos-delay="150">
          <img src={image} className={styles.logo}/>
          </div>
          <p className={styles.description}>Experience a little WiZR magic with a free trial. No obligation. Our self-guided tutorial will have you up and running in minutes.</p>
          <StyledButton 
          title="Try free trial today"
          extraClass="graybtn"
          link="./get-started"
          email={true}
          icon={<IoIosArrowForward/>}
          />
      </div>
    );
}

export default FreeTrial;