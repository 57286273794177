import React, { useState, useEffect } from "react";
import * as styles from "./OpenPositions.module.scss";
import Position from "../../atoms/Position";
import { graphql, useStaticQuery } from "gatsby";
import StyledButton from "../../atoms/StyledButton";

const OpenPositions = () => {
  const { allWpJob } = useStaticQuery(
    graphql`
      query {
        allWpJob {
          edges {
            node {
              id
              title
              content
              jobDetails {
                deadline
                workType
              }
              slug
              categories {
                nodes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    `
  );
  // console.log(allWpJob);
  const [department, setDepartment] = useState([
    "technology",
    "management",
    "marketing",
    "sales",
    "internship",
  ]);
  const [list, setList] = useState();
  const [index, setIndex] = useState(0);
  const [numberOfItems, setNumberOfItems] = useState(4);

  useEffect(() => {
    let arrayList = [];
    allWpJob.edges
      .filter((job) => department.includes(job.node.categories.nodes[0].slug))
      .map((job) => {
        arrayList.push(job);
      });
    setList(arrayList);
    setNumberOfItems(4);
  }, [department]);

  return (
    <div id='positions' className={styles.container}>
      <div className={styles.upper}>
        <h2>All Open Positions</h2>
      </div>
      <div className={styles.filters}>
        <h3
          onClick={() => (
            setDepartment([
              "technology",
              "marketing",
              "sales",
              "internship",
            ]),
            setIndex(0)
          )}
          className={`${index == 0 && styles.active}`}
        >
          All
        </h3>
        <h3
          onClick={() => (setDepartment(["technology"]), setIndex(1))}
          className={`${index == 1 && styles.active}`}
        >
          Technology
        </h3>
        <h3
          onClick={() => (setDepartment(["marketing"]), setIndex(2))}
          className={`${index == 2 && styles.active}`}
        >
         UI/UX Design
        </h3>
        <h3
          onClick={() => (
            setDepartment(["sales"]), setIndex(3)
          )}
          className={`${index == 3 && styles.active}`}
        >
         Sales
        </h3>
      </div>
      <div className={styles.positionContainer}>
        {list &&
          list.map((job, index) => {
            if (index < numberOfItems) {
              return (
                <Position
                  deadline={job.node?.jobDetails?.deadline}
                  workType={job.node?.jobDetails?.workType}
                  title={job.node?.title}
                  link={job.node?.slug}
                />
              );
            }
            return null;
          })}
      </div>
      {numberOfItems < list?.length && (
        <div
          onClick={() => {
            setNumberOfItems(numberOfItems + 4);
          }}
          className={styles.lower}
        >
          <StyledButton title="See More" icon extraClass="orangeGray" />
        </div>
      )}
    </div>
  );
};

export default OpenPositions;
