import React, {useState} from 'react';
import * as styles from './HiringQuotes.module.scss';
import SwipeableViews from "react-swipeable-views";
import Pagination from "../Pagination";
import {quotes} from "../../../constants/hiringQuotes";
import {Image} from "../../../hooks/gatsbyImg";

const HiringQuotes = () => {
    const [index, setIndex] = useState(0);
    const handleChangeIndex = (index) => {
        setIndex(index);
    };

    const Text = ({name, position, image, description}) => {
        return (
            <div className={styles.items}>
                <Image filename={image} height='7.5rem' width='7.2rem' />
                <p className={styles.description}>{description}</p>
                <p className={styles.description}><strong>{name} | </strong>{position}</p>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
                    {quotes.map((value)=>{
                        return <Text name={value.name} position={value.position} image={value.image} description={value.description} />
                    })}
                </SwipeableViews>
                {/* <Pagination dots={quotes.length} index={index}/> */}
            </div>
        </div>
    );

};

export default HiringQuotes;
