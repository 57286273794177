// extracted by mini-css-extract-plugin
export var aboutUs = "HeroBanner-module--aboutUs--i52VN";
export var aboutUsSecond = "HeroBanner-module--aboutUsSecond--Acqs+";
export var bannerModules = "HeroBanner-module--bannerModules--fuZxf";
export var children = "HeroBanner-module--children--Xkf4X";
export var contactUs = "HeroBanner-module--contactUs--k1VtM";
export var container = "HeroBanner-module--container--tzrhR";
export var h3 = "HeroBanner-module--h3--nQPNy";
export var hiringPage = "HeroBanner-module--hiringPage--nzEiI";
export var image = "HeroBanner-module--image--U2Fdw";
export var magazineBanner = "HeroBanner-module--magazineBanner--4i2vy";
export var moduleBanner = "HeroBanner-module--moduleBanner--SP0MC";
export var presentationBanner = "HeroBanner-module--presentationBanner--dalef";
export var servicesBanner = "HeroBanner-module--servicesBanner--64X-d";
export var sharingPlatform = "HeroBanner-module--sharingPlatform--3colc";
export var whyDialog = "HeroBanner-module--whyDialog--h62kH";
export var whyPage = "HeroBanner-module--whyPage--XcSDj";