import React, {useEffect, useState} from 'react';
import * as styles from './HeroBanner.module.scss';
import { Image } from '../../../hooks/gatsbyImg';

const HeroBanner = ({children, filename, extraClass, imageHeight}) => {

    const [height, setHeight] = useState(1000);
    useEffect(() => {
        imageHeight ? setHeight(imageHeight) : setHeight(window.innerHeight);
    });

    return (
        <div className={`${styles.container} ${styles[extraClass]}`} style={{height:`calc(${height}px - 50px)`}}>
            <Image filename={filename} className={styles.image} />
            <div className={styles.children}>{children}</div>
        </div>
    );
};

export default HeroBanner;
