import React from 'react';
import * as styles from './Position.module.scss';
import {Link} from "gatsby";

const Position = ({title, link, deadline, workType}) => {
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.description}>
                    <h3>{title}</h3>
                    <p>{workType &&<span> Part Time | {workType} </span>}</p>
                </div>
                <Link to={'../job/' + link} title='Read more' className={styles.readMore}>Read more ></Link>
            </div>
            <hr/>
        </div>
    );
};

export default Position;
